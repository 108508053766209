export default function CircleTooltip({tooltip, multiline, position}) {
  let style = 'has-tooltip-arrow'
  if (multiline) {
    style += ' has-tooltip-multiline'
  }

  switch (position) {
    case 'top': {
      style += ' has-tooltip-top'
      break
    }
    case 'bottom': {
      style += ' has-tooltip-bottom'
      break
    }
    case 'left': {
      style += ' has-tooltip-left'
      break
    }
    case 'right': {
      style += ' has-tooltip-right'
      break
    }
  }

  return (
    <span className={style} data-tooltip={tooltip}><i className="fa fa-question-circle"/></span>
  )
}

import isArray from 'lodash/isArray';

const Notification = ({ data, type }) => {
  if (typeof data.response !== "undefined") {
    data = data.response.errors.map(error => error.message);
  }

  if (!isArray(data)) {
    data = [data];
  }

  return data.map(message => (
    <div className={`notification is-${type}`} key={Math.random()}>
      { message }
    </div>
  ));
}

export default Notification;

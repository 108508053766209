import React from 'react';
import CircleTooltip from './circle-tooltip.jsx'

const Input = props => (
  <>
    {props.suffix && (
      <label htmlFor={props.name} className="label">
        {props.label} {props.tooltip && <CircleTooltip tooltip={props.tooltip} />}
      </label>
    )}
    <div className={`field ${props.suffix ? 'has-addons' : ''}`}>
      {!props.suffix && (
        <label htmlFor={props.name} className="label">
          {props.label} {props.tooltip && <CircleTooltip tooltip={props.tooltip} />}
        </label>
      )}
      <p className="control">
        <input className="input" {...props} id={props.name} />
      </p>
      {props.suffix && (
        <p className="control">
          <a className="button is-static">{props.suffix}</a>
        </p>
      )}
    </div>
  </>
);

export default Input;
